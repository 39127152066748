import React, {useEffect, useState} from 'react';
import {ThemeProvider} from 'styled-components';
import {theme} from 'common/theme/appminimal';
import {DrawerProvider} from 'common/contexts/DrawerContext';
import {ResetCSS} from 'common/assets/css/style';
import Sticky from 'react-stickynode';
import Navbar from 'containers/AppMinimal/Navbar';
import Footer from 'containers/AppMinimal/Footer';
import Seo from 'components/seo';
import GlobalStyle, {
    AppWrapper,
    ContentWrapper,
    GradientWrapper,
} from 'containers/AppMinimal/app-minimal.style';
import NavbarOut from "../containers/AppMinimal/NavbarOut";

const CookiePolicy = () => {


    useEffect(() => {

    }, []);

    return (
        <ThemeProvider theme={theme}>
            <>
                <Seo title="Contact"/>
                {/* end of head */}

                <ResetCSS/>
                <GlobalStyle/>
                {/* end of global and reset style */}

                {/* start app minimal landing */}
                <AppWrapper>
                    <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active" className="black-sticky">
                        <DrawerProvider>
                            <NavbarOut/>
                        </DrawerProvider>
                    </Sticky>
                    <ContentWrapper>
                        <section style={{background: "#1089ff", width: "100%", height: "100px"}}></section>
                        <section style={{width: "50%", margin: "auto", textAlign: "center", paddingTop: "80px"}}>
                            <h1>Contact us!</h1>
                            <p>Email us at <b>support@secret-sms.com</b></p>
                            <p>We will be happy to help you.</p>
                            <p>We respond in less than 24 hours.</p>
                        </section>
                    </ContentWrapper>
                    <GradientWrapper>
                        <div style={{'height': '100px'}}></div>
                        <Footer/>
                    </GradientWrapper>
                </AppWrapper>
                {/* end of app minimal landing */}
            </>
        </ThemeProvider>
    );
};
export default CookiePolicy;
